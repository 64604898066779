import { forwardRef } from 'react'
import IconLib from '@/components/Icon/IconLib'

const Icon = forwardRef(
  ({ size, className, fill, icon, viewBox, onClick, count, height, width }, ref) => {
    if (icon == 'Cart' && count > 0) {
      return (
        <div ref={ref} style={{ position: 'relative', cursor: 'pointer' }} onClick={onClick}>
          <svg
            width={size}
            height={size}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="8" y="2" width="8" height="8" rx="4" stroke="black" strokeWidth="2" />
            <rect x="3" y="5" width="18" height="17" fill="#DD5F13" />
          </svg>
          <span
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '24px',
              height: '24px',
              fontSize: '14px',
              top: '3px',
              left: '1px',
            }}
          >
            {count}
          </span>
        </div>
      )
    } else {
      return (
        <svg
          width={`${width || size}`}
          height={`${height || size}`}
          className={className}
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox}
          onClick={onClick}
          dangerouslySetInnerHTML={{ __html: `${IconLib[icon]}` }}
          ref={ref}
        ></svg>
      )
    }
  }
)

export default Icon
